@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;600;700&display=swap');

body {
    font-family: 'Lexend', sans-serif;
    background-color: #eceef1 !important;
    /* font-weight: 400; */
}

.canvasElem {
    border: 1px solid cyan;
}

/* context menu */
.react-contextmenu-wrapper {
    /* height: min-content;
    width: min-content;
    display: inline-block;
position: absolute;  */
    display: block;
}

.selectedDiv {
    display: block;
}

/* Over WRite */
/* 

/* My uploads Tabs */
.bp4-tab-list {
    justify-content: center;
}

/* Pagination */
.ant-form-item {
    margin-bottom: 0px !important;
}

.anticon {
    margin-left: 0px !important;
    vertical-align: 0.1em !important;
}

/* carousel Pagination Dots */
.kdCslO {
    display: none !important;
}

.hHSNMm {
    display: none !important;
}

.eWjCzc {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.6em;
    /* background-color: rgba(103,58,183,0.1); */
    color: #333;
    /* box-shadow: 0 0 2px 0px #333; */
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
}

.eWjCzc {
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 3.6em !important;
}

.eWjCzc:hover {
    color: #dad2ea !important;
}

.eAyARa:hover {
    color: #dad2ea !important;
}

.eAyARa {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #dad2ea !important;
    font-size: 2.6em !important;
    /* margin-top: -26px; */
}

.bp4-drawer-body {
    flex: 1 1 auto;
    line-height: 18px;
}

.grUxXV {
    /* height: 126px !important; */
    margin-top: 10px;
}

.guIbWC {
    justify-content: left !important;
    width: 100% !important;
}

.ant-popover-message-title {
    padding: 0% !important;
}

.eWjCzc {
    margin-bottom: 0% !important;
}

/* End Overwrite */
/* ///////////////////////////////// */

/* MEDIA  Queries Overall project Except dailogs and popusps */

@media only screen and (min-width: 900px) and (max-width: 1028px) {
    .animaionaniatecss {
        font-size: 10px !important;
    }

    .editPropertiesOption {
        display: none !important;
    }

    .hHJTFD {
        width: 80% !important;
    }

    /* .banner {
        padding-left: 3.9% !important;
    }

    .librarybrowermaincss {
        padding-left: 3.9% !important;
    }
    
    .templateContainer {
        padding-left: 3.9% !important;
    } */

    .left-side-menu {
        height: 180% !important;
    }

    .assetcards {
        margin-right: 5px !important;
    }
}

@media only screen and (min-width: 1029px) and (max-width: 1200px) {
    .animaionaniatecss {
        font-size: 10px !important;
    }

    .editPropertiesOption {
        display: none !important;
    }

    .hHJTFD {
        width: 80% !important;
    }

    .banner {
        padding: 24px !important;
    }

    .librarybrowermaincss {
        padding: 20px !important;
    }

    .projectcardsContainer {
        padding: 24px !important;
    }

    .templateContainer {
        padding: 24px !important;
    }

    .assetcards {
        margin-right: 36px !important;
    }
}

/* Medai Queries End */

.appmenu {
    background-color: rgba(255, 255, 255, 1) !important;
    color: white !important;
    height: 52px !important;
    margin-top: -1px;
    border-right: 1px solid #d9d5d5 !important;
}

.appmenu_topbar {
    background-color: rgba(255, 255, 255, 1) !important;
    color: white !important;
    height: 52px !important;
    padding: 1px 17px 0px 5px !important;
    margin-top: -1px;
    border-right: 1px solid #d9d5d5 !important;
}

.studioloader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #0a9764;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    display: block !important;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #0a9764;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.navbaricons {
    margin-right: 2%;
    color: #acacac;
}

.sidebar {
    background-color: #eceef1 !important;
    color: white !important;
}

.sidebar-footer {
    color: black;
    height: 50px;
    position: absolute;
    width: 100%;
    bottom: 0;
    list-style-type: none;
    padding-bottom: 9.5em;
    padding-left: 16%;
    padding-right: 16%;
}

.sidebarFooterSvg {
    margin-left: 4%;
    margin-right: 24%;
}

.drop-link {
    color: white !important;
}

.drop-link:active {
    background-color: #9d9dad !important;
}

.drop-link:hover {
    background-color: #9d9dad !important;
}

.left-side-menu {
    width: 220px !important;
    padding-top: 1% !important;
    box-shadow: none !important;
    top: 47px !important;
}

.left-side-menu-condensed #wrapper .content-page .content {
    width: calc(100% - 70px);
}

.content {
    transition: 0.25s ease-in-out;
    width: 100%;
    height: calc(100vh - 50px);
    overflow: hidden;
    overflow-y: auto;
}

@media (width < 768px) {
    .content {
        width: 100%;
    }
}

.content-page {
    display: flex;
    margin: 0 !important;
    padding: 0px !important;
}

.bannerTitle {
    color: #6362b0;
}

.bannerLink {
    color: #6362b0;
    font-size: 15px;
}

.bannerProjectSubtitle {
    font-weight: 700;
    margin-left: 19px !important;
}

#sidebar-menu>ul>li {
    text-align: center;
    display: flex;
}

#sidebar-menu>ul>li.mm-active>a {
    border-left: 3px solid transparent !important;
    background-color: transparent !important;
    color: #6362b0 !important;
}

#sidebar-menu>ul>li:hover>a {
    border-left: 3px solid transparent !important;
    background-color: transparent !important;
    color: #6362b0 !important;
}

#sidebar-menu>ul>li>a:hover svg,
#sidebar-menu>ul>li>a:focus svg,
#sidebar-menu>ul>li>a:active svg {
    color: black !important;
}

#sidebar-menu>ul>li>a {
    margin: 1px !important;
    padding: 2px 12px !important;
    font-size: 14px !important;
    display: flex !important;
    justify-content: flex-start !important;
    width: 100%;
}

#sidebar-menu>ul>li>a svg {
    /* margin-right: 16px !important; */
    color: black !important;
    width: 23px !important;
    height: 21px !important;
}

.projectpracss {
    margin-top: 11px !important;
    font-size: 15px !important;
    margin-left: -16px !important;
}

.username h6 {
    color: #302f2f !important;
}

.title {
    color: #302f2f;
}

.drop-button svg {
    color: #302f2f !important;
}

.lesson {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: #717171 !important;
}

.lessonName {
    color: black;
    margin-left: 7px;
}

.HomeTop {
    background-image: linear-gradient(90deg, #fbddfb 0%, #b8eff5 100%) !important;
    padding-top: 8px;
    height: 48px;
}

.banner {
    background-color: #eff8ff;
    padding: 10px;
    padding-top: 2% !important;
    padding-bottom: 2% !important;
}

.bp4-button.bp4-minimal.bp4-active {
    background: rgba(143, 153, 168, 0.3);
    box-shadow: none;
    color: #1c2127;
    height: 33px;
}

.HomeButton {
    color: #424242 !important;
    padding-right: 12px !important;
    background-color: transparent !important;
    border-radius: 6px !important;
    border: 1px solid transparent !important;
    width: 64px;
    background-image: none !important;
    box-shadow: none !important;
}

.bp4-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    justify-content: center;
    padding: 5px 10px;
    text-align: left;
    vertical-align: middle;
    min-height: 0px !important;
    min-width: 30px;
}

.fontColorButton {
    opacity: 0;
    margin-left: -54px;
}

.HomeTopzoomButtonGroup {
    margin-left: 8px;
    background-color: rgba(241, 241, 241, 1) !important;
    border-radius: 10px !important;
    height: 20px;
}

.HomeTopzoomButton {
    background-color: rgba(241, 241, 241, 1) !important;
    width: 0px !important;
    font-size: 10px !important;
    border-radius: 4px;
}

.primary-button {
    color: white;
    box-shadow: none;
    background-color: #6362b0;
    border: 1px solid #6362b0;
    border-radius: 10px;
    min-width: 70px;
    min-height: 36px;
    background-image: none;
    font-size: 14px;
    height: auto;
    padding: 6px 16px;
    cursor: pointer;
}

.secondary-button {
    color: white;
    background-color: #3bbb8d;
    border: 1px solid #3bbb8d;
    border-radius: 10px;
    min-width: 70px;
    min-height: 36px;
    padding: 6px 16px;
    background-image: none;
    box-shadow: none;
    height: auto;
    font-size: 14px;
    cursor: pointer;
}

:where(.primary-button, .secondary-button):where(:hover, :focus) {
    border: 1px solid transparent !important;
    color: #fff !important;
}

.primary-button:where(:hover, :focus) {
    background-color: #6362b0 !important;
    box-shadow: 0 0 6px 0px #ffffff40;
    ;
}

.secondary-button:where(:hover, :focus) {
    background-color: #3bbb8d !important;
    box-shadow: 0 0 6px 0px #ffffff40;
    ;
}

.loadMoreBtn {
    color: white !important;
    /* border: 1px solid transparent !important; */
    justify-content: center;
    /* margin-left: 36%; */
    background-color: #3bbb8d !important;
    border-radius: 6px !important;
    width: 100%;
    background-image: none !important;
    box-shadow: none !important;
    font-size: 14px;
}

.HomeButtons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.HomeTopText {
    display: flex;
    color: #918f8f;
    gap: 12px;
    /* margin-top: -7px; */
}

.HomeTopInput {
    background-color: #ffffff !important;
    color: #918f8f !important;
    border-radius: 40px !important;
}

.HomeTopText2 {
    padding: 1%;
}

.HomeTopArrow {
    background-image: none !important;
    background-color: white !important;
    border: none !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
}

.DeleteButton {
    background-color: white !important;
    border-radius: 30px !important;
    height: 40px;
    width: 150px;
    border: 1px solid transparent !important;
    margin: 1%;
    background-image: none !important;
    box-shadow: none !important;
}

.ToolbarColumn {
    border-right: 1px solid #d9d9d9;
    padding-left: 22px;
}

.FileButton {
    width: 80%;
    /* margin: 4%; */
}

.accountDetail {
    margin-left: 45px;
    padding: 2%;
    width: 205px;
    height: 225px;
    margin-top: -7px !important;
    background-color: white;
    box-shadow: 1px 1px 4px #888888;
}

.countDetail {
    width: 90px;
    height: 388px;
    margin-left: -43px;
    background-color: white;
    box-shadow: 1px 1px 4px #888888;
    overflow: scroll;
}

.row21 {
    height: 22px;
    margin-top: 0px;
    font-size: 12px;
}

.userProfile {
    height: 37px;
    margin-left: 10px;
    margin-top: 4px;
    width: 41px;
    border-radius: 100%;
}

.userProfileName {
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.userProfileRole {
    font-weight: 300;
    font-size: 12px;
    color: #545353;
}

.userProfileCompany {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #545353;
}

.userProfileExpiry {
    font-weight: 300;
    font-size: 11px;
    margin-top: 20px !important;
    font-style: italic;
    color: #545353;
}

.FileDetail {
    padding: 4%;
    width: 109%;
    margin-left: 0%;
    background-color: white;
    box-shadow: 1px 1px 4px #888888;
}

.contextQuestion {
    padding: 4%;
    width: 109%;
    margin-left: 350%;
    margin-top: 100%;
    background-color: white;
    box-shadow: 1px 1px 4px #888888;
}

.FileDetailButtons {
    justify-content: flex-end;
    display: flex;
}

.FileDetailButton {
    border: none !important;
    font-weight: 700 !important;
}

.TextStylingButtonsection {
    color: black;
    width: 200px;
    height: 30px;
    font-size: 11px;
    border: 2px solid #dedede;
    border-radius: 7px;
}

.TextStylingButton {
    background-color: white !important;
    border: 1px solid black !important;
    width: 150px;
    margin: 1%;
    background-image: none !important;
    box-shadow: none !important;
}

.layerIcon {
    font-size: 84px !important;
}

.rightSideDrawer {
    top: 91px !important;
    height: calc(100% - 91px) !important;
    width: 260px !important;
    margin-right: 0px !important;
    background-color: #ffff !important;
    color: #cacaca !important;
    overflow-x: auto;
    position: fixed !important;
}

.rightSideDrawe {
    top: 90px !important;
    height: calc(100% - 91px) !important;
    width: 260px !important;
    margin-right: 0px !important;
    background-color: #ffff !important;
    color: #cacaca !important;
    overflow-x: auto;
    position: fixed !important;
}

.rightSideDrawer h4 {
    overflow: visible !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    margin-top: -2px;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #f0f1f4;
    border-radius: 16px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    -webkit-background-clip: content-box;
    background-clip: content-box;
    border-radius: 16px;
    border: 3px solid #f0f1f4;
}

.bp4-button.bp4-minimal.bp4-active {
    background: none !important;
    box-shadow: none;
    color: #1c2127;
    height: 33px;
}

.drawer {
    top: 91px !important;
    left: 57px !important;
    height: fit-content;
    max-height: calc(100% - 90px);
    width: 260px !important;
    background-color: #ffff !important;
    color: #cacaca !important;
    position: fixed !important;
}

.bp4-drawer.bp4-position-left.bp4-overlay-exit-active {
    -webkit-transform: translateX(-120%) !important;
    transform: translateX(-120%) !important;
}

.drawer-container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 26px;
}

.drawer-container span,
.drawer-container input {
    font-size: 14px;
}

.background_css {
    font-size: 9px !important;
    margin-top: -19px !important;
    font-weight: 600 !important;
    color: #444343 !important;
    margin-left: -17px !important;
}

.drawer h4 {
    overflow: visible !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 0px !important;
}

.bp4-drawer-header {
    box-shadow: none !important;
    padding: 6px 8px 6px 12px;
    font-size: 18px;
}

.drawerbuttons svg {
    color: #cacaca;
}

.drawerCloseButton {
    text-align: end;
    /* padding-top: 10px; */
}

.drawerCloseButto {
    text-align: end;
    margin-left: -7px;
}

.drawerCloseButt {
    text-align: end;
    /* padding-top: 10px; */
}

.drawerbuttons {
    margin-top: 8px;
    display: contents !important;
}

.drawerbuttonss {
    margin-top: -9px;
    display: flex !important;
}

.addSlideButton {
    width: 105px;
    height: 65px;
    background-color: #ececec !important;
    border: 0px solid #b3b2b2;
    box-sizing: border-box;
    border-radius: 8px !important;
    /* box-shadow: 2px 2px 4px 0px #888888 !important; */
    z-index: 0 !important;
    font-size: 10px;
    padding-top: 2%;
}

.addSlideButton svg {
    color: rgba(0, 0, 0, 0.54);
}

.RightSideBarTitle {
    color: black;
    font-size: 18px;
    margin: 6%;
}

.embedCloseButton {
    background-image: none !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    margin-left: 91% !important;
    margin-top: -59px !important;
}

.embedCloseButtons {
    background-image: none !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    margin-left: 91% !important;
    margin-top: -17px !important;
}

.slideName {
    font-size: 16px;
    color: #545353;
    font-weight: 400;
    margin-left: 10.4px;
    line-height: 19px;
}

.animationButton {
    background-color: white !important;
    border-radius: 10px !important;
    height: 100px !important;
    width: 100px !important;
    border: 1px solid transparent !important;
    box-shadow: 2px 2px 4px 2px #888888 !important;
    margin: 4%;
    background-image: none !important;
}

.RightSideBarClick {
    background-color: transparent !important;
    background-image: none !important;
    box-shadow: none !important;
    color: black !important;
    font-weight: 500 !important;
    margin: 6%;
}

.RightSideBarClickSwitch {
    margin: 8%;
}

.behaviour {
    margin: 6%;
}

.behaviourButton {
    color: #ffffff !important;
    border: 1px solid transparent !important;
    background-color: #0a9764 !important;
    border-radius: 8px !important;
    width: 93%;
    margin-bottom: 8%;
    background-image: none !important;
    box-shadow: none !important;
    height: 22px;
    font-size: 10px;
    margin-left: 5px;
}

.nobehaviourButton {
    color: #525050 !important;
    border: 1px solid transparent !important;
    background-color: #d1d1d1 !important;
    border-radius: 8px !important;
    width: 93%;
    margin-bottom: 8%;
    background-image: none !important;
    box-shadow: none !important;
    height: 22px;
    font-size: 10px;
    padding: 2%;
    margin-left: 5px;
}

.behaviourBox {
    box-shadow: 2px 2px 2px 1px grey;
    padding: 6%;
    margin-left: 5%;
    margin-right: 2%;
    margin-bottom: 8%;
}

.shapeTitle {
    font-size: 22px;
}

.shapesOverlay {
    background-color: white !important;
    width: 510px !important;
    margin-left: -1621px !important;
    margin-top: -43px !important;
}

.addShapesButtons {
    display: flex;
    justify-content: flex-end;
}

.addShapesButtonsCancel {
    background-color: transparent !important;
    background-image: none !important;
    box-shadow: none !important;
    color: black !important;
    font-weight: 500 !important;
    margin: 1%;
}

.addShapesButtonsSave {
    background-color: #059744 !important;
    background-image: none !important;
    box-shadow: none !important;
    color: white !important;
    font-weight: 500 !important;
    margin: 1%;
    width: 10%;
}

.shapesIcons svg {
    margin: 8%;
    padding: 8%;
}

.addText {
    display: flex;
    justify-content: center;
    padding: 4%;
    background-color: #d1d1d1;
    /* margin: 2%; */
}

.elementbuttons {
    background-image: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: rgba(84, 83, 83, 1) !important;
    border: 1px solid transparent !important;
    line-height: 14px;
    margin-left: 10px !important;
}

.elementbuttons svg {
    color: #cacaca;
    height: 11px;
    width: 12px;
}

.greenbuttons {
    background-image: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #cacaca !important;
    font-size: 11px !important;
}

.greenbuttons svg {
    min-height: 18px;
    min-width: 10px;
    margin-right: 10px;
}

.PropertiesInput {
    border: none;
    font-size: 12px;
}

.positionbutns {
    border: none;
    /* font-size: 12px; */
    color: #545353 !important;
    font-weight: 400;
}

.PropertiesInpu {
    border: none;
    margin-top: 19px;
    margin-left: 16px;
}

.PropertiesInpu input {
    width: 26px !important;
    height: 26px;
    border: none;
    /* background: #ffffff; */
    box-sizing: border-box;
    /* border-radius: 9px; */
    margin-bottom: 28px;
    margin-left: -8px;
    padding: 0px;
}

.PropertiesInput input {
    width: 74px;
    height: 26px;
    border: none;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 18px;
    font-size: 10px;
}

.ElementsTitle {
    padding-left: 13px;
    font-size: 13px !important;
}

.ElementsTitl {
    padding-left: 14px;
    color: rgba(84, 83, 83, 1);
    font-size: 18px !important;
    margin-top: 8px !important;
    margin-bottom: 4px !important;
}

.slideproinputtwo {
    padding-right: 75px !important;
    padding-left: 4px !important;
    width: 191px !important;
    height: 26px !important;
    margin-left: -2px !important;
}

.elements {
    height: 25.81px;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 8%;
    margin-left: 8%;
    margin-bottom: 8%;
    width: 208px;
    border: 1px solid transparent;
}

.elements:hover {
    border: 1px solid #cec1c1;
}

.elementsection {
    /* margin: -22px; */
    height: calc(100% - 100px) !important;
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
}

.elementsectionone {
    margin: -22px;
    height: inherit;
    overflow-x: hidden;
}

.ElementCard {
    overflow: auto;
    background-color: transparent;
    height: 25.81px;
    width: 198px !important;
    align-items: center;
    font-size: 12px;
}

.ElementPropertiesSection {
    padding: 3%;
    margin-top: -14px;
}

/* //.behaviour */
.addAssetimage {
    height: 400px;
    overflow-y: scroll;
}

.Assetimage {
    margin: 0%;
    padding: 4%;
    overflow-y: scroll;
    height: inherit;
    width: 244px;
    margin-top: 10px;
}

.Assetimageone {
    margin: 0%;
    padding: 4%;
    overflow-y: scroll;
    /* height: inherit; */
    width: 428px;
    margin-top: 16px;
    height: 20vh;
}

.Assetimageone12 {
    margin: 0%;
    padding: 4%;
    overflow-y: scroll;
    /* height: inherit; */
    width: 428px;
    margin-top: 16px;
    height: 30vh;
}

.canvasElem:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: #cdcdcd 2px dotted;
}

.canvasEle:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: #cdcdcd 2px dotted;
}

.projectCardButtons button {
    color: #828282 !important;
    margin-right: 4px;
    background-color: transparent !important;
    border-radius: 10px !important;
    width: 61px;
    border: 1px solid #828282;
    background-image: none !important;
    box-shadow: none !important;
    padding: 2px 2px;
    font-size: 12px;
}

.buttonproject {
    font-size: 10px !important;
    height: 20px !important;
    margin-top: 19px !important;
    width: 47px !important;
}

.buttonprojec {
    font-size: 10px !important;
    height: 20px !important;
    margin-top: 19px !important;
    width: 72px !important;
}

.projectCardButtons button:hover {
    background-color: rgba(0, 149, 105, 0.1) !important;
    color: #009569 !important;
    border: 1px solid transparent !important;
}

.container-fluid {
    padding: 0px !important;
}

.projectcardsContainer {
    width: 100%;
    padding: 16px !important;
}

.buttonsContainer {
    margin-top: 20px;
    padding-left: 2% !important;
    overflow-x: auto;
    height: 320px;
}

.uploadImagemainContainer {
    margin-top: -26px;
    height: 420px;
    overflow: auto;
}

.uploadImageContainer {
    margin-top: 32px;
    width: 70px;
    height: 70px;
    margin: 8%;
    position: relative;
}

.projectcards {
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    border-radius: 5px !important;
}

.card_creation {
    width: 263px;
    height: 276px;
    margin-right: 15px !important;
    margin-bottom: 12px !important;
    padding: 10px !important;
    margin-left: 4px !important;
    border-radius: 5px !important;
    background-color: #f1effc !important;
}

.image-shadow {
    box-shadow: 4px 4px 4px 0px #00000025;
}

.uploadImageContainer .btn {
    position: absolute;
    top: 19%;
    left: 79%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 2px;
    border: none;
    cursor: pointer;
    height: 4px;
    width: 2px;
    text-align: center;
    background-image: none !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    padding: 12px 20px 2px 2px;
}

.uploadImageContainer .btn svg {
    position: absolute;
    top: 0%;
    left: 16%;
}

.uploadImageContainer .btn :hover {
    background-color: black;
}

.uploadImageContainer img {
    width: 70px;
    height: 70px;
    margin: 2.5%;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 0px grey;
}

.editUploadImage {
    background-color: #ffffff !important;
    padding: 8%;
    margin: 20px;
    width: 159px;
    height: 144px;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
}

.selectedSlide .bp4-popover2-target {
    position: relative;
}

.slideDetailbutton {
    position: absolute;
    top: 4px;
    right: 6px;
    /* margin-top: 12%;
    margin-left: 85%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); */
    background-color: #555;
    color: white;
    font-size: 2px;
    border: none;
    cursor: pointer;
    height: 4px;
    width: 2px;
    text-align: center;
    background-image: none !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    padding: 12px 20px 2px 2px;
    border-radius: 4px;
}

.slideDetailbutton svg {
    position: absolute;
    top: 0%;
    left: 16%;
}

.slidePropertyPopover {
    background-color: #ffffff !important;
    width: 240px;
    border: 1 solid #d3d3d3;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    box-shadow: 4px 4px 4px 4px rgb(0 0 0 / 3%);
    font-size: medium;
    padding: 16px;
    gap: 12px;
    border: 1px solid #cacacaca;
}

.slidePropertyPopoverInnerDiv {
    display: flex;
    gap: 12px;
}

.colorpicker input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.contextMenu {
    background-color: white;
    width: 240px;
    height: 260px;
    box-shadow: 0px 2px 4px grey;
    z-index: 100;
}

.assetcards {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.assetcardImage {
    width: 100%;
    height: 70%;
    border-radius: 6px;
}

.projectcardImage {
    width: 100%;
    aspect-ratio: 2/1.25;
    border-radius: 5px;
}

.projectcardName {
    font-size: 2px;
}

.carousel .thumbs {
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
    height: 48px !important;
    display: flex !important;
    width: 268px !important;
}

.projectcardMore {
    color: #ffffff !important;
    border: none;
    background-color: transparent !important;
    background-image: none !important;
    box-shadow: none !important;
    float: right;
}

.ProjectCreateButton {
    color: #ffffff !important;
    border: 1px solid transparent !important;
    background-color: #0a9764 !important;
    border-radius: 6px !important;
    width: 110%;
    font-size: 10px;
    height: 100%;
    background-image: none !important;
    box-shadow: none !important;
}

.ant-upload-list-picture-card-container {
    display: inline-block;
    width: 104px;
    height: 104px;
    margin: 9px 8px 8px 15px !important;
    vertical-align: top;
}

.ant-upload.ant-upload-select-picture-card {
    width: 91% !important;
    height: 88px !important;
    margin-left: 19px !important;
    margin-top: 3% !important;
    margin-bottom: 8px !important;
    text-align: center !important;
    vertical-align: top !important;
    background-color: #fafafa;
    border: 2px dashed #d9d9d9 !important;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.uploadMediaButton {
    color: #ffffff !important;
    background-color: #6362b0 !important;
    border-radius: 4px !important;
    width: 98%;
    font-size: 10px;
    height: 25px;
    background-image: none !important;
    box-shadow: none !important;
    /* margin-top: 21%; */
    margin-bottom: 4%;
    display: flex;
    /* justify-content: center; */
    /* margin-left: 34%; */
}

.uploadMediaButtonone {
    color: #ffffff !important;
    background-color: #6362b0 !important;
    border-radius: 4px !important;
    width: 32%;
    font-size: 10px;
    height: 31%;
    background-image: none !important;
    box-shadow: none !important;
    display: flex;
}

.replaceImageBtn {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    margin-top: 1rem;
}

.crop-btn {
    width: 20%;
    margin-top: 1rem;
}

.assetUploadButton {
    min-width: auto !important;
    width: auto;
    color: #6362b0 !important;
    border: 1px solid transparent !important;
    background-color: transparent !important;
    font-weight: 300;
    font-size: 14px;
    height: 100%;
    background-image: none !important;
    box-shadow: none !important;
}

.ProjectCreateButton1 {
    color: #ffffff !important;
    border: 1px solid transparent !important;
    background-color: #0a9764 !important;
    border-radius: 8px !important;
    width: 100%;
    font-size: 10px;
    background-image: none !important;
    box-shadow: none !important;
}

.projectCardsTitle {
    font-weight: 600;
    color: #000000;
    margin-top: 12px;
}

.assetCardsTitle {
    font-weight: 400;
    font-size: 12px;
}

.tabsTitle {
    font-weight: bold;
    margin-left: -101px;
}

.tabsSearchButtonone input {
    border-radius: 2px !important;
    box-shadow: 2px 2px 2px 2px rgb(48 44 44 / 25%) !important;
    /* height: 34px !important; */
    /* width: 111% !important; */
    height: 32px !important;
    font-size: 12px;
    margin-left: 5px !important;
}

.tabsSearchButton input {
    border-radius: 9px !important;
    border: 2px solid #c0c0c0c0;
    box-shadow: 2px 4px 4px 2px #f0f0f0 !important;
    height: 40px !important;
    font-size: 14px;
    padding-left: 30px !important;
    padding-right: 12px;
    margin-left: 0px !important;
}

.tabsSearchButton span {
    padding: 0 10px !important;
    margin: 0 !important;
    top: 50% !important;
    transform: translate(0, -50%);
}

.customisedText {
    margin: 1% !important;
    width: 98% !important;
}

.DrawerSearchButton input {
    border-radius: 10px !important;
    font-size: 12px;
}

.drawerinsertButton {
    color: white !important;
    padding-right: 12px !important;
    margin-left: 8px;
    background-color: #3bbb8d !important;
    border-radius: 6px !important;
    border: 1px solid transparent !important;
    width: 76px !important;
    height: 22px;
    background-image: none !important;
    box-shadow: none !important;
    font-size: 10px !important;
}

.hndle_css :hover {
    background-color: #3bbb8d !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 15px !important;
}

.hndle_css {
    background-color: transparent !important;
}

.drawerinsertButtonfooter {
    /* color: white !important; */
    padding-right: 12px !important;
    margin-left: 8px;
    background-color: #c4c9cd !important;
    border-radius: 12px !important;
    border: 1px solid transparent !important;
    width: 76px !important;
    height: 22px;
    background-image: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
}

.ToolBarICons :active {
    color: #504fad !important;
}

.projectcss {
    margin-left: 10px;
}

.projectcss svg {
    width: 15px !important;
    height: 12px;
}

.HomeTopButtonGrey svg {
    width: 15px !important;
    height: 12px;
}

.projectsSelect {
    border-radius: 10px !important;
    width: 90%;
}

.projectsSelec {
    border-radius: 10px !important;
    width: 32%;
}

.ant-select-selector {
    font-size: 10px;
    border-radius: 10px !important;
    justify-content: space-between !important;
}

.positioncss {
    background-color: #ffffff !important;
    margin-top: -7px !important;
    padding: 15px 10px;
    width: 230px !important;
    height: 218px !important;
    box-shadow: 1px 1px 2px #d3d3d3;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
}

.positioncs {
    background-color: #ffffff !important;
    margin-top: -7px !important;
    padding: 15px 10px;
    width: 230px !important;
    height: 160px !important;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
    overflow-y: auto;
    overflow-x: hidden;
}

.positioncs1 {
    background-color: #ffffff !important;
    margin-top: -7px !important;
    padding: 15px 10px;
    width: 230px !important;
    height: 358px !important;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
}

.positionc {
    background-color: #ffffff !important;
    margin-top: 15px !important;
    padding: 15px 10px;
    width: 230px !important;
    height: 93px !important;
    box-shadow: 1px 1px 2px #d3d3d3;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
    margin-left: -142px;
}

.labeltextcss {
    font-size: 13px !important;
    font-weight: 400 !important;
    margin-bottom: 4px !important;
    margin-left: 25px !important;
    margin-top: -19px !important;
    color: #545353;
}

.butoncss {
    border: none !important;
    background-color: transparent;
    /* margin-left: -27px !important; */
    width: 26px !important;
    margin-top: 6px;
    margin-bottom: 19px !important;
}

.tabsPopover {
    background-color: #ffffff;
    margin-top: 132px;
    padding: 15px 10px;
    width: 221px;
    /* height: 200px; */
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
    margin-left: 98%;
}

.tabsPopoverone {
    background-color: #ffffff;
    margin-top: 179px;
    padding: 15px 10px;
    width: 221px;
    height: 145px;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
    margin-left: 98%;
}

.bp4-control.bp4-switch input:checked~.bp4-control-indicator {
    background: #6362b0 !important;
    color: #fff !important;
}

.filesPopover {
    background-color: #ffffff;
    padding: 15px 10px;
    width: 240px;
    height: 368px;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
}

.tabsPopove {
    background-color: #ffffff !important;
    margin-top: 48px;

    padding: 15px 10px;
    width: 221px;
    height: 378px;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
    margin-left: -59px;
}

.assettabsPopover {
    background-color: #ffffff !important;
    margin-left: 148%;
    margin-top: 75%;
    padding: 6%;
    width: 159px;
    height: 176px;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    border-radius: 5px;
    border: 1px solid #cac6c6;
    font-size: medium;
}

.CustomiseButton {
    font-size: 11px;
    color: white;
    background-color: #0a9764;
    border-radius: 7px;
}

.wrapper {
    width: 113px;
    height: 30px;
    padding: 4px;
    display: flex;
    border-radius: 8px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
    border: 2px solid #dedede;
}

.plusminus {
    /* width: 16px; */
    display: flex;
    background: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    /* font-size: 21px; */
    color: #717171;
    /* margin-top: -5px; */
}

.plusminus svg {
    height: 10px;
    width: 10px;
}

.num {
    height: 100%;
    width: 24px;
    border: none;
    color: #000;
    font-size: 14px;
    margin-left: 7px;
    background-color: transparent;
}

.createProjectPopover {
    background-color: #ffffff !important;
    padding: 6%;
    width: 159px;
    height: 144px;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
}

.popoverTitle {
    font-weight: 600;
    font-size: 13px;
    /* line-height: 23px; */
    color: black;
}

.popoverSubtitle {
    margin-bottom: 8%;
}

.popoverSubtitleText {
    font-size: 12px;
    color: #545353;
}

.selectedSlide:active {
    border: 2px solid #6362b0;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.topbarsize {
    width: 100% !important;
    padding: 0px !important;
}

.bottomToolbar {
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-top: 1px solid #d9d9d9;
    margin: 0px !important;
    text-align: right;
    display: flex;
    justify-content: right;
}

.bottomToolbarIcons {
    margin: 4px;
    font-size: 10px !important;
}

.ant-slider-track {
    background-color: #6362b0 !important;
}

.ant-slider-handle {
    background-color: #6362b0 !important;
    border-color: #6362b0 !important;
}

.threebutton {
    background-color: #ffffff !important;
    background-image: none !important;
}

.topright {
    margin-left: 200px;
}

.bp4-dialog-header {
    align-items: center;
    background: none !important;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 0 rgb(17 20 24 / 15%);
    display: flex;
    flex: 0 0 auto;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 5px;
    z-index: 0;
    height: 69px;
    margin-left: 21px;
    margin-right: 21px;
    margin-top: 0px;
}

.bp4-dialog {
    background-color: white !important;
}

/* .DailogSize {
    height: 67vh !important;
    width: 65% !important;
    background: white !important;
    border-radius: 0px !important;
    margin-top: 69px !important;
} */

.bp4-overlay-inline .bp4-overlay-backdrop {
    position: fixed !important;
}

h4.bp4-heading,
.bp4-running-text h4 {
    font-size: 19px !important;
    font-weight: 700 !important;
}

.buttonDropdown {
    margin-top: -53px;
    margin-left: 60%;
    color: #555555;
    font-size: 12px;
}

.buttonDropdownone {
    margin-top: -19px;
    margin-left: 60%;
    color: #555555;
    font-size: 12px;
}

.bottonborder {
    border-bottom: 1px solid #7c7c7c;
    padding-bottom: 11px;
    width: 140px;
}

.iconas {
    margin-left: -14px !important;
    font-size: 12px;
    color: #837878 !important;
    margin-bottom: 17px;
}

.anticon {
    margin-left: 43px;
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bp4-navbar {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 0 0 rgb(17 20 24 / 0%), 0 1px 1px rgb(17 20 24 / 20%);
    height: 40px !important;
    padding: 0 15px;
    position: relative;
    width: 100%;
    display: contents;
    z-index: 10;
}

.bp4-navbar-group {
    align-items: center;
    display: flex;
    height: 50px !important;
}

.bp4-overlay-inline .bp4-overlay-backdrop {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
    background-color: transparent !important;
}

.navbarCustomised {
    background-image: linear-gradient(90deg, #fbddfb 0%, #b8eff5 100%) !important;
}

.projectDialog {
    background-color: #ffffff !important;
    width: 483px !important;
    height: 345px !important;
}

.projectDialogone {
    background-color: #ffffff !important;
    width: 483px !important;
}

.backgroundimageDailog {
    background-color: #ffffff !important;
    width: 800px !important;
    height: 445px !important;
}

.backgroundImages {
    margin: 1%;
    padding: 3%;
    overflow-y: scroll;
    height: 240px;
    width: 750px;
}

.detailsoverlaycss {
    justify-content: center !important;
    text-align: start;
}

.previewBox {
    width: 800px;
    height: 400px;
}

.previewBoxRow {
    width: 942px;
    padding: 20px;
}

.previewBox iframe {
    width: 393px;
    height: 271px;
}

.previewBoxInfo {
    padding: 45px;
    padding: 2%;
    width: 358px;
    height: 271px;
    background-color: white;
    margin-left: 1%;
}

.previewBoxInfoRow {
    margin-top: 12%;
}

.previewBox input {
    width: 268px;
    height: 37px;
    width: 260px;
    height: 34px;
    border: 1px solid #cccccc;
}

.previewBoxbutton {
    height: 16px;
    background: #288838 !important;
    border-radius: 3px;
    height: 34px;
    color: white !important;
    background-image: none !important;
    box-shadow: none !important;
    margin-left: 18%;
}

.previewBox svg {
    margin-top: 5px;
    margin-right: 12px;
}

/* Wizade */
.w1 {
    margin-top: 46px;
    margin-left: 175px;
    margin-right: 160px;
}

.w123 {
    margin: auto;
    width: clamp(15rem, 75%, 30rem);
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.w11 {
    /* background-image: url('./assets/images/background1.png') !important;
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    height: 100%;
    overflow-y: auto;
}

.w3 {
    width: 223px;
    height: 175px;
    margin-top: -43px;
}

.w4 {
    width: 160px;
    height: 153px;
    margin-top: -2px;
}

.w5 {
    width: 160px;
    height: 153px;
    margin-top: 36px;
    margin-left: 514px;
}

.w6 {
    margin-left: -14px;
    margin-top: 19px;
    border: 0px;
    background-color: transparent;
}

.WQ1 {
    margin-top: 4%;
    margin-left: 35%;
}

.WQNextButton {
    border-radius: 9px;
    background-color: #6362b0;
    color: white;
    border: 0px;
    min-width: 18vh;
    padding: 7px;
}

.WQTitle {
    font-weight: 200;
    font-size: 15px;
}

.WTitle,
.WQuesDesc {
    font-weight: 300;
    font-size: 14px;
}

.WQuestion {
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.WAnswer {
    background-color: #ffffff8e;
    border: none;
    font-size: 16px;
    line-height: 26px;
    color: #999;
    border-left: 5px solid #6362b0 !important;
    outline: none;
    width: 100%;
    box-shadow: none;
    padding: 6px 14px;
}

.WTextArea {
    border: 1px solid #D9D9D9 !important;
    border-radius: 12px;
}

.WAnswer>input {
    border: 0 !important;
}

.WAnswer:focus-within {
    border-left-color: #D9D9D9 !important;
}

.WQinput {
    width: 85%;
    height: 40vh;
    margin-left: 28px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
}

.WQpra {
    justify-content: center;
    text-align: center !important;
    background-color: #eeeeff !important;
    height: 24rem !important;
    width: 55% !important;
    border-radius: 16px !important;
    margin-left: 30% !important;
}

.WQTextArea {
    max-width: 90% !important;
}

.WQ51 {
    margin-left: 279px;

    border-radius: 9px;
    background-color: #6362b0;
    color: white;
    border: 0px;
    height: 28px;
}

.w12 {
    /* background-image: url('./assets/images/background12.png') !important;
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    overflow: auto;
}

.w01 {
    background-image: url('./assets/images/background.png') !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
}

.WQ15 {
    margin-top: 110px;
    margin-left: 222px;
}

.WQNext {
    display: flex;
    flex-direction: column;
    width: 250px;
    float: right;
    align-items: center;
    gap: 48px;
}

.Wcolumn {
    width: 50%;
    height: 100%;
}

@media (width < 768px) {
    .Wcolumn {
        width: 80%;
        margin: 0 auto;
    }
}

.Wcolumnone {
    width: 100%;
    height: 75vh;
    max-height: 500px;
    overflow: auto;
}

.WQpragraph {
    margin-top: -16px !important;
}

.WQvideo {
    border-radius: 20px;
    margin-top: 20px;
    height: 139px;
    width: 285px;
}

.WQremove {
    margin-left: 16rem;
    margin-top: 2%;
    color: #9c9c9c;
}

.WQslidenum {
    margin-top: 10px !important;
}

.WQsildeTwo {
    margin-top: 12% !important;
}

.WQsildeThree {
    margin-top: 35% !important;
}

.chatlistperfectscroll {
    max-height: height !important;
    width: 100% !important;
}

.uploadbutton {
    margin-top: 8 !important;
}

.assetpagecss {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.assetpagerowone {
    display: flex !important;
    justify-content: space-between !important;
}

.assetpagecardlength {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 100%;
    gap: 24px;
}

.homecardcss {
    max-width: 600px !important;
}

.homecardbodycss {
    text-transform: capitalize !important;
}

.libraryimgcss {
    width: 316px !important;
    height: 177px !important;
    margin-left: 45px;
}

.libraryimgcs {
    width: 316px !important;
    height: 177px !important;
    margin-left: -13px !important;
}

.librarybrowermaincss {
    padding: 10px;
    padding-top: 3% !important;
    /* margin-left: 10px !important; */
}

.librarybrowersecondcss {
    display: flex !important;
    justify-content: space-between !important;
    margin-right: -18px !important;
}

.libraryinputgroupcss {
    display: flex !important;
}

.libraryrowcss {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
    margin-left: -21px;
}

.librarycarddatacss {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 24px;
}

.mb-5 {
    margin-bottom: 0rem !important;
}

.librarydialogcss {
    background-color: white !important;
}

.librarycrossbuttoncss {
    width: 2vh !important;
    height: 29px !important;
    margin-left: 66vh !important;
    margin-top: -14px !important;
    border-radius: 25px !important;
    background-color: #cbcaed !important;
}

.libarydiacolonecss {
    margin-left: 9vh !important;
    font-weight: bold !important;
    color: black !important;
}

.librarycanclecss {
    margin-left: 35% !important;
    margin-top: 17px !important;
    color: white !important;
    background-color: #6362b0 !important;
}

.librarycanclecss:hover {
    color: white !important;
    background-color: #cbcaed !important;
}

.librarycanclecs {
    margin-left: 10px !important;
    margin-top: 17px !important;
    color: white !important;
    background-color: #6362b0 !important;
}

.librarycanclecs:hover {
    color: white !important;
    background-color: #cbcaed !important;
}

.librarydiacrossbuttoncss {
    border: 0px !important;
    background-color: transparent !important;
}

.librarycarouselcss {
    display: flex !important;
    width: 46% !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    margin-bottom: -475px !important;
}

.librarycarouselimgcss {
    height: 202px !important;
}

.librarycolcss {
    font-size: 12px !important;
    margin-left: 6px !important;
}

.librarytextcss {
    color: #0a9764 !important;
    font-size: 12px !important;
    margin-top: 10px !important;
}

.librarytexttwocss {
    color: #6362b0 !important;
    font-size: 12px !important;
    margin-top: 10px !important;
}

.librarytempbtncss {
    font-size: 11px !important;
    color: white !important;
    background-color: #6362b0 !important;
    border-radius: 7px !important;
}

.librarylessbtncss {
    font-size: 11px !important;
    color: white !important;
    background-color: #0a9764 !important;
    border-radius: 7px !important;
}

.libraryiconcss {
    margin-top: 10px !important;
}

.librarynamecss {
    font-weight: bolder !important;
    color: black !important;
    font-size: 1.4rem !important;
}

.librarytextinsidecss {
    font-size: 12px !important;
    margin-top: 10px !important;
}

.addcoutimageuploadcss {
    justify-content: center !important;
    padding: 4% !important;
}

.addcoutimgtitlecss {
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
}

.aniamationtextcss {
    font-size: 14px !important;
    font-weight: bold !important;
    margin-bottom: -7px !important;
}

.animaionaniatecss {
    text-align: center !important;
    margin-bottom: 25px !important;
    margin-left: -8px !important;
}

.editPropertiesOption {
    text-align: center;
    margin-top: -8px;
    display: block;
}

.slidecardsbackgrtext {
    margin-left: -2px !important;
    margin-top: 1px !important;
    font-weight: 'bolder' !important;
}

.slidecardsbackgrte {
    margin-left: -5px !important;
    margin-top: 1px !important;
    font-weight: 'bolder' !important;
}

.slidecardsbackgrtex {
    margin-left: 5px !important;
    margin-top: 1px !important;
    font-weight: 'bolder' !important;
}

.slidecrdassetcss {
    display: flex !important;
    flex-wrap: wrap !important;
}

.sidebar-menu>ul>li>a {
    margin: 1px !important;
    padding: 2px 12px !important;
    font-size: 14px !important;
    display: flex !important;
    justify-content: flex-start !important;
    width: 100%;
}

.slidecardmaincss {
    width: 50% !important;
    display: flex !important;
}

.eleprotextcss {
    margin-left: 10px !important;
    font-weight: bold !important;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: 0px !important;
}

.ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    background-color: white !important;
    border: none;
    border-bottom: 0;
    border-radius: 2px;
}

.elebahshowcss {
    display: contents !important;
    justify-content: space-between !important;
}

.spacebrwIcons {
    cursor: pointer;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border: 2px solid transparent;
}

.addshapeshowbutton {
    margin-left: 167px !important;
    margin-top: -41px !important;
    font-size: 10px !important;
    width: 100px !important;
}

.shapedivcss {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    gap: 16px 4px;
}

.slideproone {
    height: 110px !important;
    width: 113px !important;
    display: flex !important;
}

.addshapehr {
    width: 292px !important;
}

.addshapeparacss {
    padding: 12px;
    font-size: 12px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.addshapeparacss button {
    margin: 0 !important;
    padding: 0;
    width: auto !important;
}

.slideproinputone {
    width: 191px !important;
    height: 24px !important;
    font-size: 11px !important;
}

.slideproimgcss {
    height: 60px !important;
    background-repeat: repeat !important;
    display: flex !important;
    flex-direction: column !important;
    object-fit: cover !important;
    width: 70px !important;
    box-shadow: 2px 2px 4px #888888 !important;
}

.slideprobtn1 {
    width: 23px !important;
    height: 30px !important;
    margin-left: 446px !important;
    margin-top: -51px !important;
    border-radius: 24px !important;
    background-color: white !important;
}

.projecttext {
    color: black;
    font-size: 15px;
}

.ant-pagination {
    box-sizing: border-box;
    margin-top: 10px !important;
    margin-bottom: 21px !important;
    margin: 0px;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
}

/* .bp4-input-group > .bp4-icon:first-child {
    left: 4px !important;
    margin-top: 12px !important;
}
.bp4-input-group .bp4-input:not(:first-child) {
    padding-left: 45px !important;
}
.addtextbtncss {
    width: 87px !important;
    margin-left: 25vh !important;
    height: 24px !important;
    font-size: 12px !important;
    background-color: #3bbb8d !important;
    border-radius: 6px !important;
    color: white !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
}
.templateContainer {
    padding-left: '9.9%' !important;
    padding-top: '3%';
}


/* wizards style */
.backLink {
    display: flex;
    align-items: center;
    margin: 20px;
    color: #000;
    border: none;
    background: none;
}

.backLink svg {
    /* height: 10px;
    width: ; */
    margin-right: 10px;
}

.nextButton {
    display: flex;
    align-items: center;
    border-radius: 9px;
    background-color: #6362b0;
    color: white;
    padding: 7px 17px;
    border: none;
    margin-left: auto;
}

.wizardsInputs {
    background-color: #ffffff8e !important;
    border: none !important;
    color: #999;
    border-left: 5px solid #6362b0 !important;
    outline: none;
    width: 100%;
    box-shadow: none;
    padding: 5px 10px;
}

.bp4-editable-text,
.bp4-editable-text-input {
    text-decoration: inherit;
}

.wizardsInputsNext {
    background-color: #ffffff8e !important;
    border: none !important;
    color: #999;
    border-left: 5px solid #6362b0 !important;
    outline: none;
    box-shadow: none;
    padding: 5px 10px;
    width: 32rem;
}

.iframe-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
}

.iframe-wrapper::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.textcss {
    margin-left: 65% !important;
    margin-top: -15% !important;
    width: 255px !important;
}

.rowdata {
    /* display: block !important; */
    margin-top: 10px !important;
}

/* .rowdata :hover{
    background-color: #525050;
    height: 20px;
} */
.dragResizingActive {
    /* outline: rgba(202, 202, 202, 0.6) auto 2px !important;
    outline-offset: 2.5px !important; */
    border: 2px solid rgb(202, 202, 202);
    position: relative;
}

.photos-menu-conatiner {
    display: grid;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: calc(100vh - 318px);
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 12px;
    padding: 6px;
}

.photos-menu-image {
    position: relative;
}

.photos-menu-image img {
    display: flex;
    aspect-ratio: 2/1.25;
    border-radius: 3px;
    width: 100%;
}

.photos-menu-image img.tickmark {
    box-shadow: 4px 4px 4px 0px #6362b099;
}

.photos-menu-image span.tickmark {
    top: 7px;
    color: white;
    background-color: #6362b0;
    left: 7px;
    padding: 0px 4px;
    position: absolute;
    border-radius: 50%;
}

#textbox span {
    bottom: 70px;
    color: white;
    background-color: #6362b0;
    left: 12px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
}

/* #textbox span.dragResizingActive {
    display:inline !important;
    
} */
.bp4-editable-text:focus {
    outline: none !important;
    outline-offset: 0;
}

#canvasFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    background-color: #f5f5f5;

    padding: 20px;

    overflow: auto;
    position: relative;
}

.pcanvas {
    position: relative;
    overflow: auto;
    background-color: transparent;
}

.pcanvas::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.pcanvas::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}

.pcanvas::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 10px;
}

#lcanvas {
    transform-origin: center;
    /* box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1); */
    /* overflow-y: auto; */
    /* margin: 20px; */
    /* top: 50%;
    left: 50%;

    transform: translate(-50%, -50%); */
    /* white-space: nowrap; */
    /* overflow-x: scroll; */
}

/* FOR REACT EDIT_TEXT DONT CHANGE THIS++++++++++++++++++++++++++++ */
._4GdcU {
    overflow: initial;
    text-overflow: ellipsis;
    white-space: break-spaces;
    transition: background 0.2s ease;
    min-height: 28px;
}

._4GdcU:hover {
    cursor: pointer;
    background: rgba(220, 220, 220, 0.4) !important;
}

._G0k44 {
    overflow-y: auto;
    transition: background 0.2s ease;
}

/* Width */
._G0k44::-webkit-scrollbar {
    width: 8px;
}

/* Track */
._G0k44::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

/* Handle */
._G0k44::-webkit-scrollbar-thumb {
    background: #ccc !important;
}

/* Handle on hover */
._G0k44::-webkit-scrollbar-thumb:hover {
    background: #aaa !important;
}

/* Handle on drag */
._G0k44::-webkit-scrollbar-thumb:active {
    background: #666 !important;
}

._G0k44:hover {
    cursor: pointer;
    background: rgba(220, 220, 220, 0.4) !important;
}

._i5--j {
    width: 100%;
    padding: 5px;
    display: block;
    margin: 3px 0;
    height: 50%;
    /* border: 12px solid salmon; */
    scrollbar-width: thin !important;
    /* outline: rgba(45, 114, 210, 0.6) auto 2px !important;
    outline-offset: 2.5px !important; */
}

/* Width */
input._i5--j {
    border: none;
    outline: rgba(45, 114, 210, 0.6) auto 2px !important;
    outline-offset: 2.5px !important;
    background: #fefefe !important;
}

._i5--j::-webkit-scrollbar {
    width: 8px;
}

/* Track */
._i5--j::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

/* Handle */
._i5--j::-webkit-scrollbar-thumb {
    background: #ccc !important;
}

/* Handle on hover */
._i5--j::-webkit-scrollbar-thumb:hover {
    background: #aaa !important;
}

/* Handle on drag */
._i5--j::-webkit-scrollbar-thumb:active {
    background: #666 !important;
}

._TDklp {
    color: #999 !important;
}

._gmkRL {
    display: inline !important;
}

._-wzeg {
    cursor: auto !important;
}

._-wzeg:hover {
    cursor: auto !important;
}

._IYz6Z {
    display: flex;
}

._NGZSv {
    background-color: #fff !important;
    color: black !important;
    border: 0;
    outline: none;
    min-width: 28px;
}

._NGZSv:focus {
    border: 0;
    outline: none;
}

/*# sourceMappingURL=index.css.map */

/* END OF REACT_EDIT_TEXT */
.ant-modal-close {
    border-radius: 50% !important;
    background-color: #b1b1d8 !important;
    top: 4.5% !important;
    right: 2.5% !important;
}

.ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.ant-modal-close-icon svg {
    fill: white;
}

.font_css {
    font-size: 11px !important;
    margin-top: 3% !important;
}

.addText-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.w-100 {
    width: 100%;
}

.margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.custom-btn:hover {
    border-color: rgb(99, 98, 176);
    background-color: rgb(99, 98, 176);
    color: #fff;
}

.text-left {
    text-align: left;
}

.border-5 {
    border-radius: 5px;
}

.bg-blue {
    background-color: rgb(99, 98, 176);
}

.border-blue {
    border-color: rgb(99, 98, 176);
}

.text-white {
    color: #fff;
}

.text-border .dragResizingActive {
    outline: none !important;
    border: 1px solid #cacaca;
    position: relative;
}

.dragResizingActive>.parent-marker>.left-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 2px rgb(202 202 202 / 20%);
}

.dragResizingActive>.parent-marker>.right-top {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(50%, -50%);
    box-shadow: 0 0 0 2px rgb(202 202 202 / 20%);
}

.dragResizingActive>.parent-marker>.right-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(50%, 50%);
    box-shadow: 0 0 0 2px rgb(202 202 202 / 20%);
}

.parent-marker {
    position: relative;
    width: 100%;
    height: 100%;
}

.dragResizingActive>.parent-marker>.left-center {
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, 0);
    box-shadow: 0 0 0 2px rgb(202 202 202 / 20%);
}

.dragResizingActive>.parent-marker>.top-center {
    position: absolute;
    top: 0;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 2px rgb(202 202 202 / 20%);
}

.dragResizingActive>.parent-marker>.right-center {
    position: absolute;
    top: 50%;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(50%, 0);
    box-shadow: 0 0 0 2px rgb(202 202 202 / 20%);
}

.card_css .ant-card-body {
    padding: 10px !important;
}

.dragResizingActive>.parent-marker>.left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, 50%);
    box-shadow: 0 0 0 2px rgb(99 98 176 / 20%);
}

.dragResizingActive>.parent-marker>.bottom-center {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, 50%);
    box-shadow: 0 0 0 2px rgb(202 202 202 / 20%);
}

.drawer-text {
    top: 89px !important;
    right: 0px !important;
    height: min-content;
    width: 400px !important;
    background-color: #ffff !important;
    color: #cacaca !important;
    position: fixed !important;
}

.drawer-text h4 {
    overflow: visible !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 0px !important;
}

.edit-text-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
}

.body-text {
    padding: 24px;
    position: relative;
}

.horizontal-line {
    width: 300;
    background-color: #e3e3e3;
    height: 1px;
}

.body-copy {
    font-size: 1.2rem;
    color: #000;
    margin-bottom: 1rem;
}

.txt-input {
    border-radius: 5px;
}

.txt-input:hover {
    border-color: #504fad;
}

.txt-input:focus {
    border-color: #504fad;
    box-shadow: 0 0 0 2px rgb(99 98 176 / 20%);
}

.bg-grey {
    background-color: #e3e3e3;
}

.line_css {
    margin-top: 0px !important;
}

.rotatepop {
    background-color: #ffffff !important;
    margin-top: 15px !important;
    padding: 15px 10px;
    width: 206px !important;
    height: 197px !important;
    box-shadow: 1px 1px 2px #d3d3d3;
    font-size: medium;
    margin-left: -53px !important;
    border-radius: 5px;
    border: 1px solid #cac6c6;
}

.rotatepopcrop {
    background-color: #ffffff !important;
    margin-top: 17px !important;
    padding: 15px 10px;
    box-shadow: 2px 2px 6px grey;
    font-size: medium;
    margin-left: -53px !important;
    border-radius: 5px;
    border: 1px solid #cac6c6;
}

.deltext-btn {
    background-color: transparent !important;
    color: #992e2e !important;

    margin-top: 1rem;
}

.rmv-shadow .bp4-button:not([class*='bp4-intent-']) {
    box-shadow: none !important;
}

.deltext-btn:hover {
    color: #592121 !important;
}

#textEdit>div:nth-child(6)>div:nth-child(1) {
    top: -10px !important;
}

#textEdit>div:nth-child(6)>div:nth-child(2) {
    right: -10px !important;
}

#textEdit>div:nth-child(6)>div:nth-child(3) {
    bottom: -10px !important;
}

#textEdit>div:nth-child(6)>div:nth-child(4) {
    left: -10px !important;
}

.grid-area {
    width: 100%;
    height: 100%;
}

.grid-icon {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.aspect-ratio svg:focus {
    outline: rgba(255, 255, 215, 0.6) solid 2px;
}

.as-ratio {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-bottom: 9px;
}

.bp4-navbar-divider {
    border-left: 1px solid rgba(17, 20, 24, 0.15);
    height: 24px;
    /* margin: 0px 2px !important; */
    margin-bottom: 9px !important;
    margin-left: 1px !important;
}

.another_css {
    background: transparent !important;
    box-shadow: none !important;
    margin-left: 8px !important;
    color: #6c6ca9 !important;
}

.bp4-button.bp4-minimal:hover {
    background: transparent !important;
    box-shadow: none;
    color: #1c2127;
    text-decoration: none;
}

.bp4-overlay.bp4-overlay-container.bp4-overlay-inline {
    position: fixed !important;
}

:focus {
    outline: rgba(45, 114, 210, 0.6) solid 0px !important;
    outline-offset: 0px !important;
}

.divider_css {
    margin-top: 5% !important;
    border: 1px solid rgb(17 20 24 / 20%) !important;
}

.backgro_css {
    width: 52px;
    height: 10px;
    margin-left: -15px;
    margin-top: -48px;
    margin-bottom: 20px !important;
}

.metismenu li {
    list-style: none !important;
    margin-top: 10px !important;
}

/* wizard Question */
.drawerWizard {
    top: 89px !important;
    left: 57px !important;
    height: calc(100% - 92px) !important;
    width: 296px !important;
    background-color: #ffff !important;
    color: #cacaca !important;
    position: fixed !important;
}

.wizardTitle {
    padding-left: 17px;
    font-size: 13px !important;
    color: #545353 !important;
    font-weight: 400 !important;
}

.wizardTitle1 {
    padding-left: 17px;
    font-size: 12px !important;
    color: #545353 !important;
    font-weight: 400 !important;
}

.wizardTitle2 {
    font-size: 11px !important;
    margin-left: -99px;
    color: #545353 !important;
    max-width: 191px !important;
    width: 192px !important;
    border: 0px !important;
    margin-top: 4px !important;
}

.wizardTitle3 {
    font-size: 11px !important;
    margin-left: 12px;
    margin-top: 12px;
    color: #545353 !important;
}

.icons {
    margin-left: 17px;
    margin-top: 17px;
    width: 17px;
    height: 17px;
}

hr {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.textQus {
    margin-top: -3px !important;
    margin-bottom: 0px !important;
    font-size: 10px !important;
    font-weight: 800 !important;
    color: #787373 !important;
}

.iconsquestion {
    /* margin-left: 17px; */
    margin-top: -75px;
    width: 22px;
    height: 22px;
}

.Tsvg {
    margin-left: 20px;
    margin-top: 9px;
}

.HomeTopButtonGreenWizard {
    color: white !important;
    border: 1px solid transparent !important;
    margin-left: 136px;
    background-color: #3bbb8d !important;
    border-radius: 6px !important;
    width: 64px;
    height: 25px;
    margin-top: 10px;
    background-image: none !important;
    box-shadow: none !important;
    font-size: 10px;
}

.ant-modal-body {
    overflow: hidden;
}

.carousel-nav-default {
    position: absolute;
    top: 50%;
    display: block;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
    line-height: 0;
    border: 0;
    outline: none;
    cursor: pointer;
}

.carousel-nav-default.right {
    right: -25px;
}

.carousel-nav-default.left {
    left: -25px;
}

.theme-preview-modal .ant-carousel .secondary-carousel .slick-track {
    display: flex;
    gap: 16px;
}

.theme-preview-modal .ant-carousel .primary-carousel .slick-track .slick-slide img {
    aspect-ratio: 2/1;
    object-position: center;
}

.theme-preview-modal .ant-carousel .secondary-carousel .slick-track .slick-slide img {
    aspect-ratio: 1.625/1;
}

.theme-view-carousel {
    background: #7459fb;
    border-radius: 50%;
    color: white;
    z-index: 100;
    display: flex !important;
    width: 60px;
    align-items: center;
    height: 60px;
}

.menu-header {
    padding: 40px 12px;
    background-color: white;
}

.menu-header .ant-breadcrumb {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    max-width: 1440px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 28px;
}

.template-creation-module-container {
    background-color: white;
    padding: 6rem;
    font-family: 'Lexend' !important;
}

.template-creation-module-container .template-creation-module {
    display: flex;
    gap: 60px;
    flex-direction: column;
    align-items: center;
    min-width: 320px;
    max-width: 1200px;
    width: 85%;
    margin: 0 auto;
}

.subtitle-padding {
    padding-top: 13px;
}

.template-creation-module .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.template-creation-module .header .title {
    font-weight: 700;
    font-size: 46px;
    line-height: 52px;
    color: #000;
    text-align: center;
}

.template-creation-module .header .subtitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #000;
    text-align: center;
}

.template-creation-module .step-1,
.template-creation-module .step-2,
.template-creation-module .step-3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 48px;
}

.template-creation-module .step-1 {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}

.template-creation-module .template-header {
    max-width: 850px;
    margin: 0 auto;
}

.template-creation-module .template-header .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000;
    display: block;
}

.template-creation-module .step-3 .template-images-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 32px;
}

.template-creation-module .step-3 .template-images-container .image {
    border-radius: 12px;
    border: 1px solid transparent;
    overflow: hidden;
    position: relative;
}

.template-creation-module .step-3 .template-images-container .template-desc {
    display: flex;
    gap: 4px;
    flex-direction: column;
    padding: 8px 12px;
}

.template-creation-mo.template-creation-module .step-3 .template-images-container .template-desc .stepper {
    transition: 2s ease-in-out;
}

.template-creation-module .stepper .euiStepHorizontal .euiStepNumber {
    padding: 12px;
}

.template-creation-module .stepper .euiStepHorizontal .euiStepNumber {
    background: #7459fb;
    height: 60px;
    width: 60px;
    font-weight: 600;
    font-size: 32px;
    line-height: 50px;
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-creation-module .stepper .euiStepHorizontal .euiStepHorizontal__title {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-decoration: none !important;
    font-family: "Lexend";
}

.template-creation-module .step-1 .ant-input {
    font-family: "Lexend";
}

.template-creation-module .stepper .euiStepHorizontal .euiStepNumber--disabled {
    background-color: #b5b4f1;
}

.template-creation-module .stepper .euiStepHorizontal button {
    font-family: inherit;
    background-color: red;
}

.template-creation-module .stepper .euiStepHorizontal::before,
.template-creation-module .stepper .euiStepHorizontal::after {
    content: '';
    position: absolute;
    width: calc(50% - 16px);
    top: 55px;
    background-color: #d3dae6;
    z-index: 0;
}

.template-creation-module .stepper .euiStepHorizontal::before,
.template-creation-module .stepper .euiStepHorizontal::after {
    height: 2px;
    background-color: #d3dae6;
}

.template-creation-module .stepper .euiStepHorizontal-isSelected::before {
    background-color: #7459fb;
}

.template-creation-module .stepper .euiStepHorizontal-isSelected .euiStepHorizontal__title {
    color: #7459fb;
}

.template-creation-module .stepper .euiStepHorizontal .euiStepNumber--complete {
    background: #009569;
}

.template-creation-module .stepper .euiStepHorizontal-isComplete::before,
.template-creation-module .stepper .euiStepHorizontal-isComplete::after {
    background-color: #009569;
}

.template-creation-module .stepper .euiStepHorizontal-isComplete .euiStepHorizontal__title {
    color: #009569;
}

.template-creation-module .stepper .euiStepHorizontal__item:first-of-type>.euiStepHorizontal::before,
.template-creation-module .stepper .euiStepHorizontal__item:last-of-type>.euiStepHorizontal::after {
    display: block;
}

.modal-container .header {
    display: flex;
    gap: 16px;
    align-items: center;
}

.modal-container .header .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #141010;
}

.ant-modal-root .ant-modal-mask {
    display: none;
}

.ant-modal-root .ant-modal-content {
    border: 1px solid #948db8;
    border-radius: 10px;
    box-shadow: 0px 6px 6px 0px #cacaca70;
}

.ant-modal-root .ant-modal-close .ant-modal-close-icon {
    color: #7459fb;
    font-size: 16px;
}

.template-creation-module .disabled {
    background-color: #b5b4f1;
    cursor: not-allowed;
}

.clickable {
    cursor: pointer;
}

.step-3-modal .ant-modal-content {
    background: #ffffff;
    border: 2px solid #7459fb;
}

.step-3-modal .modal-container {
    padding: 20px;
}

.step-3-modal .template-creation-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
}

.step-3-modal .template-creation-container .header {
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: center;
}

.step-3-modal .template-creation-container .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #7459fb;
}

.step-3-modal .template-creation-container .subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #7459fb;
}

.step-3-modal .template-creation-container .use-custom-template {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.step-3-modal .template-creation-container .template-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.step-3-modal .template-creation-container .template-content .button-group {
    display: flex;
    margin-top: 12px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.step-3-modal .template-creation-container .template-content .button-group .primary-button {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background: #7459fb;
    padding: 6px 24px;
    border-radius: 10px;
    color: white;
    width: fit-content;
}

.step-3-modal .template-creation-container .template-content .button-group .link-button {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #767676;
}

.step-3-modal {
    min-width: fit-content;
}

.step-3-modal .modal-icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.step-3-modal .modal-container .party-icon {
    position: absolute;
    top: 16px;
    left: 20px;
}

.step-3-modal .modal-container .party-icon svg {
    width: 50px;
    height: 50px;
}

.dnd-copy~div {
    transform: none !important;
}

.disable {
    cursor: not-allowed;
}

.loading {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading .ant-spin {
    color: #7459fb;
    font-size: 20px;
}

.loading .ant-spin-dot-item {
    background-color: #7459fb;
}

@media (max-width: 1430px) {
    .template-creation-module {
        padding: 40px;
    }
}

.euiFieldSearch:focus {
    background-image: none !important;
}

/* TRANSFER COMPONENT */
.transfer-component {
    display: flex;
    gap: 12px;
    height: calc(100vh - 80px);
}

.transfer-component .source {
    width: 35%;
    border: 2px solid #7459fb;
    background-color: white;
    padding: 16px;
    border-radius: 10px;
}

.transfer-component .source .source-container {
    height: 100%;
    overflow-y: auto;
    padding: 0 12px;
}

.transfer-component .source .source-container .source-title {
    display: flex;
    gap: 6px;
}

.transfer-component .source .source-container .source-title .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #6d6aee;
    text-transform: capitalize;
}

.transfer-component .source .source-container .source-title .subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #6d6aee;
}

.transfer-component .source .source-container .slide-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.transfer-component .source .source-container .slide-container .dragable-content {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
}

.transfer-component .source .source-container .slide-container .dragable-content .slide-content {
    display: flex;
    width: 100%;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 10px;
    padding: 16px;
}

.transfer-component .source .source-container .slide-container .dragable-content .slide-content .slide {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    gap: 16px;
}

.transfer-component .source .source-container .slide-container .dragable-content .slide-content .slide-review {
    cursor: pointer;
}

.transfer-component .source .source-container .slide-container .dragable-content .slide-content:hover {
    border: 1px solid #009569;
    color: #009569;
}

.transfer-component .source .source-container .slide-container .dragable-content .slide-content:hover .icon path {
    fill: #009569;
}

.transfer-component .source .source-container .show-more-btn {
    display: flex;
    justify-content: center;
}

.transfer-component .source .source-container .show-more-btn .button {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6d6aee;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #009569;
}

.transfer-component .source .source-container .ant-collapse {
    border-bottom: 1px solid #7459FB;
}

.transfer-component .source .source-container .ant-collapse .ant-collapse-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
}

.transfer-component .source .source-container .ant-collapse .ant-collapse-item>.ant-collapse-header {
    padding: 0;
}

.transfer-component .source .source-container .ant-collapse .ant-collapse-item .ant-collapse-content-box {
    padding: 0;
}

.transfer-component .switch {
    margin: 16px 0;
}

.transfer-component .switch .icon {
    font-size: 32px;
}

.transfer-component .target {
    width: calc(75% - 64px);
    border: 2px solid #7459fb;
    background-color: white;
    border-radius: 10px;
    padding: 20px 24px;
}

.transfer-component .target .target-container {
    display: flex;
    height: 100%;
    overflow: auto;
    padding-right: 12px;
    flex-direction: column;
    gap: 12px;
}

.transfer-component .target .target-container .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #6d6aee;
}

.transfer-component .target .target-container .slides {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.transfer-component .target .target-container .slides .slide {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.transfer-component .target .target-container .slides .slide .slide-header {
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    gap: 20px;
}

.transfer-component .target .target-container .slides .slide .slide-header .slide-name {
    display: flex;
    align-items: center;
    white-space: pre;
    gap: 6px;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.transfer-component .target .target-container .slides .slide .slide-header .slide-operations {
    display: flex;
    align-items: center;
    gap: 12px;
}

.transfer-component .target .target-container .slides .slide .slide-content {
    min-height: 60px;
    padding: 10px;
    background: #f1f1f1;
    border: 2px solid #3c3850;
    justify-content: center;
    align-items: center;
    border-width: medium;
    border-radius: 12px;
    color: #000;
    display: flex;
    position: relative;
}

.transfer-component .target .target-container .slides .slide .slide-content .drop-container {
    min-width: 250px;
    padding: 8px;
    border-radius: 10px;
    background-color: #454545;
    justify-content: center;
    color: white;
    display: flex;
    text-transform: capitalize;
}

.transfer-component .target .target-container .slides .slide .slide-content .delete-slide {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.transfer-component .target .target-container .slides .slide .has-content {
    background: #fefefe;
    border: 2px dashed #8f8a8a;
    border-width: medium;
}

.transfer-component .target .target-container .add-slide {
    background: #e2e1e8;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #565269;
}

.transfer-component .icon {
    width: 20px;
    color: #a3a1ad;
}

.transfer-component ::-webkit-scrollbar {
    width: 8px;
}

.transfer-component ::-webkit-scrollbar-thumb {
    background-color: #6d6aee;
    width: 8px;
    border-radius: 12px;
}

.transfer-component ::-webkit-scrollbar-track {
    background: linear-gradient(to right, transparent 25%, #d9d9d9 25%, #d9d9d9 75%, transparent 75%);
    border-radius: 12px;
}

/* FORM INPUT */
.form-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;
}

.form-input label {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
}

.form-input .input {
    border-radius: 32px;
    height: 48px;
    padding: 0 40px;
    font-weight: 600;
    font-size: 20px;
    border: 2px solid rgba(0, 0, 0, 0.65) !important;
}

/* THEME VIEW */
.template-image {
    display: flex;
    gap: 8px;
    flex-direction: column;
    position: relative;
}

.template-image .image .image-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3137254902);
    align-items: center;
    justify-content: center;
    display: none;
    flex-direction: column;
    gap: 16px;
}

.template-image .image .image-preview>span {
    background: #7459fb;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    color: white;
    cursor: pointer;
}

.template-image .image:hover {
    border: 1px solid #7459fb90 !important;
    box-shadow: 0px 0px 5px 2px #7459fb90;
}

.template-image .selected {
    border: 2px solid #7459fb !important;
    box-shadow: 0px 0px 7px 3px #7459fb99 !important;
}

.template-image .image:hover .image-preview {
    display: flex;
}

.template-image .image:hover .image-preview .icon path {
    fill: white;
}

.template-image .image .main-image {
    width: 100%;
    height: 100%;
}

.template-image .template-desc .title {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
}

.template-image .template-desc .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
}

/* NAVIGATION BUTTON GROUP */
.nav-buttons {
    display: flex;
    width: 121%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.euiButton,
button.euiButton,
.nav-buttons button {
    background: #7459fb;
    color: white;
    padding: 5px 16px;
    text-decoration: none !important;
    border-radius: 5px;
}

.nav-buttons button .eui-textTruncate {
    font-size: 16px !important;
}

.euiButton.secondary {
    background: #3bbb8d;
}

body>iframe {
    display: none;
}

/* Step-4 */
.step-4 {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.step-4 .lesson-builder {
    display: flex;
    background-color: white;
    flex-direction: column;
    border: 1px solid #c4c3c3;
    border-radius: 10px;
}

.step-4 .lesson-builder .back-button {
    padding: 16px 24px;
}

.lesson-builder-container {
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 20px 40px 40px;
    width: 75%;
    min-width: 800px;
    margin: 0 auto;
}

.lesson-builder-container .header {
    display: block;
    color: #313131;
}

.lesson-builder-container .lesson-builder-slides-conatiner {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lesson-builder-container .lesson-builder-slides-conatiner .question-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.lesson-builder-container .lesson-builder-slides-conatiner .question-container .question {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
}

.lesson-builder-container .lesson-builder-slides-conatiner .question-container .options {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lesson-builder-container .lesson-builder-slides-conatiner .question-container .options .option {
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: rgba(49, 49, 49, 0.4588235294);
    padding: 0 16px;
    border-left: 7px solid #6362b0;
}

.lesson-builder-container .lesson-builder-slides-conatiner .No-question {
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
}

.lesson-builder-container .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0;
    left: 0;
}

.lesson-builder-container .footer .slide-no {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.layout-content-padding {
    padding: 40px 24px;
}

.primary-header {
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #28262680;
    font-size: revert;
}

.custom-card {
    position: relative;
    padding: 24px 18px;
    background-color: #3C3850;
    border-radius: 8px;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.custom-card .header {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}

.custom-card .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.custom-card .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.custom-card .hover-section {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.custom-card:hover .hover-section {
    opacity: 0.3;
}

.custom-card:hover .hover-section {
    opacity: 1;
}

.custom-card .action-dots {
    opacity: 0;
    transition: 0.5s ease;
}

.custom-card:hover .action-dots {
    opacity: 1;
    transition: 0.5s ease;
}

.custom-card .custom-card-image {
    border-radius: 8px;
    width: 212px;
    height: 150px;
}

.template-card {
    width: 100%;
}

.template-card:hover {
    outline: 1px solid #7459fb;
    transition: 0.5s ease;
}

.bg-line-sketch.ant-select,
.bg-line-sketch {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='132' height='3' viewBox='0 0 132 3' fill='none'%3E%3Cpath d='M1 2C6.49314 -0.999196 125.507 3.9993 131 1' stroke='black' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
}

.bg-line-sketch {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='189' height='3' viewBox='0 0 189 3' fill='none'%3E%3Cpath d='M1 1C8.90167 3.9992 180.099 -0.999296 188 2' stroke='black' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
}

.search-input.ant-input-affix-wrapper {
    border-radius: 20px;
    padding: 4px 18px;
    border: 1px solid #bababa !important;
}

.search-input.ant-input-affix-wrapper>input.ant-input {
    background: #ffffff;
    border-radius: 44px;
    font-style: 14px !important;
    padding: 2px;
}

.divider_cs {
    margin-left: -12px !important;
    width: 94% !important;
    background-color: #dfdfdf !important;
}

.saved-resource-btn {
    background-color: #e6f5f0 !important;
    /* background-color: #000 !important; */
    border: 1px solid #92dfc5 !important;
    width: 140px !important;
    height: 145px !important;
    border-radius: 10px !important;
}

.saved-resource-btn-active {
    background-color: #009569 !important;
    border: 1px solid #d2bb93 !important;
    width: 140px !important;
    height: 145px !important;
    border-radius: 10px !important;
}

.my-curriculum-btn {
    background-color: #fff3de !important;
    border: 1px solid #62caa7 !important;
    width: 140px !important;
    height: 145px !important;
    border-radius: 10px !important;
}

.my-curriculum-btn-active {
    background-color: #efc781 !important;
    border: 1px solid #efc781 !important;
    width: 140px !important;
    height: 145px !important;
    border-radius: 10px !important;
}

.my-student-btn {
    background-color: #eaefff !important;
    border: 1px solid #ecab8f !important;
    width: 128px !important;
    height: 145px !important;
    border-radius: 10px !important;
}

.my-assessment-btn {
    background-color: #ffded0 !important;
    border: 1px solid #ecab8f !important;
    width: 140px !important;
    height: 145px !important;
    border-radius: 10px !important;
}

.studio-project-btn {
    background-color: #e7deff !important;
    border: 1px solid #d0c2fa !important;
    width: 140px !important;
    height: 145px !important;
    border-radius: 10px !important;
}

.primary-custom-tab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    top: 5px;
    border-top: 10px solid #7459fb;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.primary-custom-tab .tab-text {
    margin: 0px 0rem !important;
}

.primary-custom-tab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 6px !important;
    background: #7459fb !important;
}

.primary-custom-tab .tab-text {
    margin: 2px 4rem;
}

.primary-custom-tab .ant-tabs-nav-wrap {
    overflow: visible !important;
}

.primary-custom-tab .ant-tabs-tab {
    padding: 16px 75px !important;
}

.primary-custom-tab .ant-tabs-tab-active .primary-header {
    color: #6d6aee !important;
}

.w-90 {
    width: 90% !important;
}

.w-30 {
    width: 30% !important;
}

.w-20 {
    width: 20% !important;
}

.w-80 {
    width: 80% !important;
}

.br-xs {
    border-radius: 5px !important;
}

.br-sm {
    border-radius: 10px !important;
}

.br-xl {
    border-radius: 51.3901px !important;
}

.svg-md svg {
    width: 20px;
    height: 20px;
}

.svg-xs svg {
    width: 10px;
    height: 10px;
}

.border-transparent {
    border: transparent !important;
}

.mx-6 {
    margin-left: 5%;
    margin-right: 5%;
}

.mb-6 {
    margin-bottom: 5% !important;
}

img.image-fit {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: bottom;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-white {
    color: white !important;
}

.color-black {
    color: black !important;
}

.color-gray {
    color: #7d7d7d;
}

.no-hover {
    pointer-events: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.p-bottom {
    padding-bottom: 14px !important;
}

.circle {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
}

.fs-sm {
    font-size: 14px !important;
}

.fs-md {
    font-size: 18px !important;
}

.fs-lg {
    font-size: 22px !important;
}

.fs-xl {
    font-size: 24px !important;
}

.fs-xxl {
    font-size: 28px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.opacity-9 {
    opacity: 0.9 !important;
}

@media (min-width: 768px) {
    .fs-md-30 {
        font-size: 30px !important;
    }
}

@media (width < 1024px) {
    .template-listing-header {
        flex-direction: column;
        gap: 24px;
    }
}

/* buttons */

.btn-primary {
    background: #7459fb;
    border-color: #7459fb;
    color: white;
    font-weight: 700;
}

.btn-primary.ant-btn:hover,
.btn-primary.ant-btn:focus {
    background: #7459fb;
    border-color: #7459fb;
    opacity: 0.8;
}

.btn-secondary {
    background: #3bbb8d;
    border-color: #3bbb8d;
    color: white;
    font-weight: 700;
}

.btn-secondary.ant-btn:hover,
.btn-secondary.ant-btn:focus {
    background: #3bbb8d;
    border-color: #3bbb8d;
    opacity: 0.8;
}

.btn-tertiary {
    background: #878787;
    border-color: #878787;
    color: white;
    font-weight: 700;
}

.btn-tertiary.ant-btn:hover,
.btn-tertiary.ant-btn:focus {
    background: #878787;
    border-color: #878787;
    opacity: 0.8;
}

.btn-outline-primary {
    background: transparent;
    border: 1px solid #7459fb !important;
    color: #7459fb;
}

.btn-outline-quaternary {
    background: transparent;
    border: 1px solid #646464;
    color: #454545;
}

.btn-outline-quaternary.ant-btn:hover,
.btn-outline-quaternary.ant-btn:focus {
    background: #efc781;
    border-color: #efc781;
    opacity: 0.8;
}

.btn-outline-secondary {
    background: transparent;
    border: 1px solid #646464;
    color: #454545;
}

.btn-outline-secondary.ant-btn:hover,
.btn-outline-secondary.ant-btn:focus {
    background: #009569;
    border-color: #009569;
    opacity: 0.8;
}

/* buttons End */

/* colors */

.primary-color {
    color: #7459fb;
}

.grey-color {
    color: #646464;
}

.color-purple-1 {
    color: #8d4ae1;
}

.color-purple-2 {
    color: #5c3091;
}

.color-purple-1 {
    color: #8d4ae1;
}

.color-purple-2 {
    color: #5c3091;
}

.secondary-color {
    color: #3bbb8d;
}

.tertiary-color {
    color: #878787;
}

.bg-purple {
    background: #8d4ae1;
}

.bg-green {
    background: #65d083;
}

.primary-border {
    border: 1.16796px solid #7459fb;
}

.secondary-border {
    border: 1.16796px solid #3bbb8d;
}

.tertiary-border {
    border: 1.16796px solid #878787;
}

.primary-custom-tab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    top: 5px;
    border-top: 10px solid #7459fb;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.primary-custom-tab .tab-text {
    margin: 0px 0rem !important;
}

.primary-custom-tab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 6px !important;
    background: #7459fb !important;
}

.primary-custom-tab .tab-text {
    margin: 2px 4rem;
}

.primary-custom-tab .ant-tabs-nav-wrap {
    overflow: visible !important;
}

.bg-color-1 {
    background: #f9faf2;
}

.bg-color-2 {
    background: #fdf8ec;
}

.bg-color-3 {
    background-color: #efc781;
}

.carousel-nav-default {
    position: absolute;
    top: 50%;
    display: block;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
    line-height: 0;
    border: 0;
    outline: none;
    cursor: pointer;
}

.carousel-nav-default.right {
    right: -25px;
}

.carousel-nav-default.left {
    left: -25px;
}

.primary-modal .ant-modal-content {
    border: 2px solid #7459fb;
    border-radius: 10px;
    border: 1px solid #7459fb;
    border-radius: 10px;
    overflow: hidden;
}

.theme-view-carousel {
    background: #7459fb;
    border-radius: 50%;
    color: white;
    z-index: 100;
    display: flex !important;
    width: 60px;
    align-items: center;
    height: 60px;
}

.theme-view-carousel.right {
    right: -40px;
}

.theme-view-carousel.left {
    left: -40px;
    justify-content: flex-end;
}

.bg-lesson {
    background: #E7F8FE;
}

.bg-game {
    background: #F9F4E8;
}

.bg-quiz {
    background: #FAF3FF;
}

.bg-assembly {
    background: #EAFCF8;
}

.bg-worksheet {
    background: #FDF8EC;
}

.bg-icon-lesson {
    background: #7459FB;
}

.bg-icon-game {
    background: #FF8328;
}

.bg-icon-quiz {
    background: #DC4CB4;
}

.bg-icon-assembly {
    background: #367035;
}

.bg-icon-worksheet {
    background: #F0DE7A;
}

.icon-button-link {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
}

.remove-arrow .hGPzOw {
    background-color: none;
    box-shadow: none;
}

.rec-swipable {
    gap: 24px;
}

.rec-item-wrapper {
    width: auto !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #b7b4b4 !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 13px !important;
    /* box-shadow: 2px 4px 4px 2px #f0f0f0 !important; */
}

.setHeightInput input {
    height: 31px !important;
}

.ml-4px {
    margin-left: 4px;
}

.spinner-position {
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
}

.gap-1 {
    gap: 32px;
}

.gap-2 {
    gap: 24px;
}

.gap-3 {
    gap: 16px;
}

.gap-4 {
    gap: 12px;
}

.gap-5 {
    gap: 8px;
}

.horizontalLine {
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
    width: 100%;
}

.cross_btn {
    width: 23px !important;
    height: 30px !important;
    margin-left: 446px !important;
    margin-top: -51px !important;
    border-radius: 24px !important;
    background-color: white !important;
}

.image_text {
    margin-left: 28px !important;
    font-size: 17px !important;
    color: black !important;
    margin-top: -3px !important;
}

.filter-checkbox .ant-checkbox .ant-checkbox-inner {
    background: #eaefff !important;
    border: 1px solid #b5b4f1 !important;
}

.filter-checkbox .ant-checkbox .ant-checkbox-inner::after {
    border-bottom: 2px solid #7777ff !important;
    border-right: 2px solid #7777ff !important;
}

.filter-checkbox .ant-checkbox-checked,
.filter-checkbox .ant-checkbox-checked::after {
    border: none !important;
}

.input,
textarea,
select,
button {
    font-family: inherit !important;
}

.cr_sub {
    color: #000000 !important;
    font-weight: 300 !important;
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-size: 17px !important;
}

.cr_title {
    font-weight: 700 !important;
    color: #000000 !important;
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-size: 35px !important;
}

.eui-textTruncate {
    max-inline-size: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    font-size: 14px !important;
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.banner_pdg {
    padding: 60px 35px 60px 21px !important;
}

.p-5 {
    padding: none !important;
}

.btn_pop {
    border: white;
    background-color: white;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin-left: 14px;
}

.card_pop {
    margin-top: 191px;
    padding: 15px 10px;
    width: 224px;
    height: 147px !important;
    font-size: medium;
    margin-left: 100% !important;
    background: #ffffff;
    border: 1.06024px solid #5c3091 !important;
    box-shadow: 0px 4.24096px 4.24096px rgb(0 0 0 / 5%);
    border-radius: 3.18072px;
}

.text-underline {
    text-decoration: underline;
}

@media (width < 1024px) {
    html {
        font-size: 10px !important;
    }
}

@media (width < 768px) {
    html {
        font-size: 8px !important;
    }
}

@media (width < 425px) {
    html {
        font-size: 5px !important;
    }
}

.wizard-modal .ant-modal-body {
    padding: 0;
    border-radius: 10px;
}

.wizard-modal .ant-modal-body .ant-input:focus {
    outline: 0;
    border: 0;
    box-shadow: none;
}

.wizard-modal .ant-modal-body .wizard-layout {
    display: flex;
    aspect-ratio: 2/1.5;
    flex-direction: column;
    position: relative;
}

.wizard-modal .ant-modal-body .wizard-layout .go-back-btn {
    padding: 12px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 16px;
    cursor: pointer;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-background-image-1 {
    position: absolute;
    top: 0;
    right: 26px;
    width: min(300px, 100%);
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-background-image-2 {
    position: absolute;
    bottom: 26px;
    left: 0;
    width: min(100px, 100%);
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container {
    height: max(300px, 85%);
    width: max(300px, 85%);
    margin: auto;
    padding: 32px 32px 12px;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .go-back-btn {
    position: absolute;
    top: 0;
    left: 0;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content {
    height: calc(100% - 100px);
    display: flex;
    overflow-y: auto;
    flex-direction: column;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .ant-input,
.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content input,
.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content textarea {
    box-shadow: none;
    outline: none;
    border: none;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQRadioGrp {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQRadioGrp .ant-input-group-addon {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    background: none !important;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQRadioGrp .ant-input-group-addon .ant-radio-input:focus+.ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(99, 98, 176, 0.2);
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQRadioGrp .ant-input-group-addon .ant-radio-inner {
    border-color: #6362b0 !important;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQRadioGrp .ant-input-group-addon .ant-radio-inner::after {
    background-color: #6362b0 !important;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent .WInputs {
    width: calc(65% - 24px);
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent .more-info-input {
    background-color: #eeeeff;
    border-radius: 20px;
    padding: 28px;
    margin-right: 6px;
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent .more-info-input .title {
    color: #6362b0;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent .more-info-input .WTextArea {
    color: #000;
    font-size: 14px;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent .more-info-input .max-count {
    bottom: 6px;
    font-size: 12px;
    right: 32px;
}

@media (width < 1024px) {
    .wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent {
        flex-direction: column;
    }

    .wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent .WInputs {
        width: 100%;
    }

    .wizard-modal .ant-modal-body .wizard-layout .wizard-container .wizard-content .WQuizContent .more-info-input {
        width: max(350px, 40%);
    }
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .Wizard-footer {
    margin-top: 18px;
}

.wizard-modal .ant-modal-body .wizard-layout .wizard-container .Wizard-footer .slide-counter {
    white-space: pre;
}

@media (width < 768px) {
    .wizard-modal .ant-modal-body .wizard-layout .wizard-container {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 36px 24px;
    }
}